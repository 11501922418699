#main {
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  background-repeat: repeat;
  background-position: center;
  margin: 0px;
  border: 0px;
  background-attachment: fixed;
  background-image: url('assets/img1.gif');
  background-size: 275px;
}

.wrapper {
  height: 100vh;
  /*This part is important for centering*/
  display: grid;
  place-items: center;
}

.typing-demo {
  background-color: aliceblue;
  width: 22ch;
  animation: typing 3.5s steps(22) infinite, blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}
