.quote {
    width: 400px;
    border: 1px;
    background-color: black;
    overflow-wrap: break-word;
    position: absolute;
    box-shadow: rgb(213, 0, 249, 0.4) -5px 5px, rgba(213, 0, 249, 0.3) -10px 10px, rgba(213, 0, 249, 0.2) -15px 15px, rgba(213, 0, 249, 0.1) -20px 20px, rgba(213, 0, 249, 0.05) -25px 25px;
}

.quoteText {
    font-family: Arial, Helvetica, sans-serif;
    color: #D500F9;
    font-size: 24px;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    margin: 12px;
}

.person {
    font-family: Arial, Helvetica, sans-serif;
    color: #D500F9;
    font-size: 24px;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    margin: 12px;
}